<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
        transition="scale-transition"
        width="40"
      />

      <h2>Webshop Market</h2>
    </div>

    <v-spacer></v-spacer>

    <span>{{
        $date()
          .format('DD/MM/YYYY')
      }}</span>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-on="on" v-bind="attrs" color="white">
          <v-icon>mdi-palette</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list-item>
          <v-list-item-content
          ><v-list-item-title class="font-weight-bold">
            {{ $t('actions.dark') }}</v-list-item-title
          >
          </v-list-item-content>
          <v-list-item-action
          ><v-switch v-model="$vuetify.theme.dark" />
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </v-card> </v-menu
    >

    <v-menu offset-y content-class="cursor-pointer">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="white"
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-earth</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          class="lang"
          v-for="(item, index) in languages"
          :key="index"
        >
          <v-list-item-title @click="changeLang(item.value)">
            <v-icon :color="item.value === language ? 'primary' : ''">
              {{ item.icon }}
            </v-icon>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  data: () => ({
    menu: false,
    languages: [
      {
        value: 'en',
        label: 'English',
        icon: 'mdi-flag',
      },
      {
        value: 'de',
        label: 'Deutsch',
        icon: 'mdi-flag',
      },
      {
        value: 'zh',
        label: '中国',
        icon: 'mdi-flag',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      lat: 'lat',
      lng: 'lng',
      dark: 'dark',
      language: 'language',
    }),
  },
  watch: {
    '$vuetify.theme.dark': {
      handler(val) {
        localStorage.theme = val ? 'dark' : 'light';
      },
    },
  },
  methods: {
    changeLang(lang) {
      if (localStorage.language === lang) return;
      localStorage.language = lang;
      this.$i18n.locale = lang;
      this.$router.push({
        name: this.$route.name,
        params: { lang },
      });
      this.$store.dispatch('language', lang);
    },
  },
  created() {
    this.$i18n.locale = this.$route.params.lang || 'de';
    localStorage.language = this.$route.params.lang || 'de';
    this.$store.dispatch('language', this.$route.params.lang || 'de');
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.lang:hover {
  background-color: var(--v-accent-lighten2);
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
</style>
